<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M13.207 3.498H14c.83 0 1.5.67 1.5 1.5v8.994c0 .828-.67 1.499-1.5 1.499H2c-.83 0-1.5-.67-1.5-1.5V4.998c0-.828.67-1.499 1.5-1.499h.793L5.647.646A.5.5 0 0 1 6 .5h4a.5.5 0 0 1 .353.146l2.854 2.852zm-7-1.998L3.353 4.352A.5.5 0 0 1 3 4.498H2c-.277 0-.5.223-.5.5v8.994c0 .276.223.499.5.499h12c.277 0 .5-.223.5-.5V4.998c0-.276-.223-.499-.5-.499h-1a.5.5 0 0 1-.353-.146L9.793 1.5H6.207z"
    />
    <path
      d="M11.001 8.995A2.999 2.999 0 0 0 8 5.996a3 3 0 1 0 3.001 2.999zm-1 0A1.999 1.999 0 0 1 8 10.993a2 2 0 1 1 2.001-1.998z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
